import { useEffect, useState } from 'react';

import { globalState } from './models';
import { portalGate as portalGateToBackground } from './portalGates/toBackground';
import type { StoreItemEventArgument } from './types/events';

export function useContentIndexingMessage(
  hasSearchQuery: boolean,
  isOnline: boolean,
): {
  contentIndexingMessage: string | undefined;
} {
  const isMigratingSearchDatabase = globalState((state) => state.isMigratingSearchDatabase);

  const [contentLoadedState, setContentLoadedState] = useState<
    | {
        hasLoadedAll: boolean;
        hasLoadedAny: boolean;
      }
    | undefined
  >();

  useEffect(() => {
    const callback = (state: {
      hasLoadedAll: boolean;
      hasLoadedAny: boolean;
    }) => setContentLoadedState(state);
    const listener = portalGateToBackground.on(
      'stores__documentContent__loaded-state-changed',
      callback as (args: StoreItemEventArgument) => void,
    );
    return () => {
      listener.off('stores__documentContent__loaded-state-changed', callback);
    };
  }, []);

  let contentIndexingMessage: string | undefined;
  if (isMigratingSearchDatabase) {
    contentIndexingMessage = 'Migrating search database to new schema, results may be incomplete..';
  } else if (!hasSearchQuery || !contentLoadedState || contentLoadedState.hasLoadedAll) {
    contentIndexingMessage = undefined;
  } else if (isOnline) {
    contentIndexingMessage = `Search results may not include all matches until Reader finishes indexing your library.`;
  } else if (contentLoadedState.hasLoadedAny) {
    contentIndexingMessage =
      'Some results may be missing, some of your documents were not downloaded yet. You are currently offline.';
  } else {
    contentIndexingMessage = 'Warning: no documents downloaded yet. You are currently offline.';
  }
  return { contentIndexingMessage };
}
